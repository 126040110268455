<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu v-if="Admin"/>
      <LeftMenu1 v-else />
      <div class="registry-wrapper p-0 bg-transparent overflow-hidden">
        <div class="filter-wrapper">
          <companyFilter @showChange="gettingValueChanged" />
        </div>
        <div class="home-registry-wrapper">

          <div class="col-12 px-0">
            <div v-if="showLoader == 1" class="lds-dual-ring-registry" id="loading"></div>
          </div>

          <company v-if="showLoader == 0 && !enterpriseAdmin" :searchedUser="filter.search" :displayedCompany="companies"
            :page="pager" :filterTextRecievedUser="filterText" :message="parentMessage" :getDataCompany="getDataCompany" />

        <companyDetailSign v-if="showLoader == 0 && enterpriseAdmin" :searchedUser="filter.search"
            :displayedCompany="Entcompanies" :page="pager" :filterTextRecievedUser="filterText" :message="parentMessage"
            :getDataCompAgreements="getDataCompAgreements" />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import LeftMenu from "../../../components/leftmenu/LeftMenu.vue";
import LeftMenu1 from "../../../components/Enterprise/leftmenu/LeftMenu.vue";
import Navbar from "../../../components/header/Navbar.vue";
import company from "../../../components/admin/companies/company.vue";
import companyDetailSign from "../../../components/admin/companies/companyAgreements.vue";

import companyFilter from "../../../components/admin/companies/companyFilter.vue";

export default {
  name: "companies",
  data: function () {
    return {
      Admin: false,
      companies: [],
      Entcompanies: [],
      parentMessage: false,
      errorMessage: null,
      showLoader: 0,
      page: 1,
      pager: {},
      perPage: 3,
      pages: [],
      success: null,
      error: null,
      count: 0,
      filter: {},
      pageSize: "15",
      filterText: "Select",
      enterpriseId: '',
      enterpriseAdmin: false,


    };
  },
  methods: {
    gettingValueChanged(value) {
      this.parentMessage = value
    },
    getDataCompany() {
      this.showLoader = 1;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}registry/listing?page=${this.page
          }&size=${this.pageSize ? this.pageSize : ""
          }&expiration_date=${this.filter.expiration_date ? this.filter.expiration_date : ""
          }&termination_date=${this.filter.termination_date ? this.filter.termination_date : ""
          }&effective_date=${this.filter.effective_date ? this.filter.effective_date : ""
          }&status=${this.filter.status ? this.filter.status : ""
          }&verified=${this.filter.verified ? this.filter.verified : ""
          }&search=${this.filter.search ? this.filter.search : ""
          }`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.companies = response.data.data.listing;
            this.pager = response.data.data;
            this.count = response.data.data.totalItems;
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
    getDataCompAgreements() {
      this.showLoader = 1;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}enterprise/registry/Agreements?enterprise_id=${this.enterpriseId}&page=${
            this.page
          }&size=${this.pageSize ? this.pageSize : ""}&expiration_date=${
            this.filter.expiration_date ? this.filter.expiration_date : ""
          }&termination_date=${
            this.filter.termination_date ? this.filter.termination_date : ""
          }&effective_date=${
            this.filter.effective_date ? this.filter.effective_date : ""
          }&status=${this.filter.status ? this.filter.status : ""}&verified=${
            this.filter.verified ? this.filter.verified : ""
          }&search=${this.filter.search ? this.filter.search : ""}`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.Entcompanies = response.data.data.listing;
            this.pager = response.data.data;
            this.count = response.data.data.totalItems;
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
    //     getDataCompany() {
    //   this.showLoader = 1;
    //   const startDate = this.filter.startDate ? this.filter.startDate.replace(/%20/g, '') : '';
    //   const endDate = this.filter.endDate ? this.filter.endDate.replace(/%20/g, '') : '';

    //   axios
    //     .post(
    //       `${process.env.VUE_APP_API_URL}registry/listing?page=${
    //         this.page
    //       }&size=${
    //         this.pageSize ? this.pageSize : ""
    //       }&endDate=${endDate}&startDate=${startDate}&search=${
    //         this.filter.search ? this.filter.search : ""
    //       }&expiration_date=${
    //         this.filter.expiration_date ? this.filter.expiration_date : ""
    //       }&termination_date=${
    //         this.filter.termination_date ? this.filter.termination_date : ""
    //       }&effective_date=${
    //         this.filter.effective_date ? this.filter.effective_date : ""
    //       }&status=${
    //         this.filter.status ? this.filter.status : ""
    //       }&verified=${
    //             this.filter.verified ? this.filter.verified : ""
    //           }`,
    //       {}
    //     )
    //     .then((response) => {
    //       if (response.data.statusCode == 200) {
    //         this.companies = response.data.data.listing;
    //         this.pager = response.data.data;
    //         this.count = response.data.data.totalItems;
    //         this.showLoader = 0;
    //       } else {
    //         this.errorMessage = response.data.message;
    //         this.showLoader = 0;
    //       }
    //     });
    // },
  },
  watch: {
    "$route.query.page": {
      immediate: true,
      handler(page) {
        page = parseInt(page) || 1;
        if (page !== this.pager.currentPage || page == this.pager.totalPages + 1)
         {
          this.showLoader = 1;
          axios
            .post(
              `${process.env.VUE_APP_API_URL}registry/listing?page=${page ? page : ""
              }&size=${this.pageSize ? this.pageSize : ""
              }&expiration_date=${this.filter.expiration_date ? this.filter.expiration_date : ""
              }&termination_date=${this.filter.termination_date ? this.filter.termination_date : ""
              }&effective_date=${this.filter.effective_date ? this.filter.effective_date : ""
              }&status=${this.filter.status ? this.filter.status : ""
              }&verified=${this.filter.verified ? this.filter.verified : ""
              }&search=${this.filter.search ? this.filter.search : ""
              }`,
              {}
            )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.companies = response.data.data.listing;
                this.pager = response.data.data;
                this.count = response.data.data.totalItems;
                this.showLoader = 0;
              } else {
                this.errorMessage = response.data.message;
                this.showLoader = 0;
              }
            });
        }
        {
          this.showLoader = 1;
          axios
            .post(
              `${process.env.VUE_APP_API_URL}enterprise/registry/Agreements?enterprise_id=${this.enterpriseId}&page=${
                page ? page : ""
              }&size=${this.pageSize ? this.pageSize : ""}&expiration_date=${
                this.filter.expiration_date ? this.filter.expiration_date : ""
              }&termination_date=${
                this.filter.termination_date ? this.filter.termination_date : ""
              }&effective_date=${
                this.filter.effective_date ? this.filter.effective_date : ""
              }&status=${
                this.filter.status ? this.filter.status : ""
              }&verified=${
                this.filter.verified ? this.filter.verified : ""
              }&search=${this.filter.search ? this.filter.search : ""}`,
              {}
            )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.Entcompanies = response.data.data.listing;
                this.pager = response.data.data;
                this.count = response.data.data.totalItems;
                this.showLoader = 0;
              } else {
                this.errorMessage = response.data.message;
                this.showLoader = 0;
              }
            });
        }
      },
    },
    
  },
  mounted() {
    const enterpriseId = localStorage.getItem("enterprise_id");
    if (enterpriseId !== null && enterpriseId !== undefined) {
        console.log("Company enterprise_id from localStorage:", enterpriseId);
        this.enterpriseId = enterpriseId;
        this.enterpriseAdmin = true;
        this.getDataCompAgreements();
    } else {
        console.log("enterprise_id not found in localStorage");
    }

    this.$root.$on("filtersCallForCompany", (data, filterText) => {
        this.filter = data;
        this.filterText = filterText;
        if (data.pageSize) {
            this.pageSize = data.pageSize.text;
        }
        if (this.enterpriseAdmin) { // Corrected variable name
            this.getDataCompAgreements();
        } else {
            this.getDataCompany();
        }
    });

    if (localStorage.getItem("permissions") == 1) {
        this.Admin = true;
    }
},

  components: {
    Navbar,
    LeftMenu,
    company,
    companyFilter,
    LeftMenu1,
    companyDetailSign
  },
};
</script>
<style  scoped>
.lds-dual-ring-registry {
  position: absolute;
  z-index: 999;
  padding-top: 50% !important;
}
</style>