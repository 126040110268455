<template>
    <div>
      <div class="registry-sign-up-form">
        <div class="signed-agreements">
          <div class="heading">
            <h5>Companies</h5>
          </div>
          <div class="menus">
            <form class="form-group mb-0">
              <button type="submit" class="search-icon"  @click="serach()">
                <img src="../../../assets/images/search.png" alt="" />
              </button>
              <input
                class="form-control"
                type="search"
                placeholder="Type Here..."
                v-model="filter.search"
              />
            </form>
            <button class="custom-btn2" @click="serach()">Search</button>
              <div class="dropdown pr-2" >
                    <button class="btn p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-menu p-0 m-0 cur-pointer" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item"  @click="downloadCSV()">Export CSV</a>
                    </div>
                </div>
          </div>
        </div>
        <!-- <div class="form-group">
          <button type="submit" class="search-icon" @click="serach()">
            <img src="../../../assets/images/search.png" alt="" />
          </button>
          <input
            type="search"
            v-model="filter.search"
            class="form-control"
            placeholder="Type here..."
          />
        </div> -->
        <div class="" v-bind:class="classObject">
          <table class="table table-hover">
            <thead class="thead-light">
              <tr>
                <!-- table head with checkbox -->
                <!-- <th scope="col">
                    <label class="chck">
                      <input type="checkbox">
                      <span class="geekmark signCheck"></span>
                     </label>
                     <div class="ml-3">Company Name
                      <img src="../../../assets/images/sort.png" alt="" class="sort-icons" @click="sortBy('company_name')"/>
                    </div>
                  </th> -->
                  <th scope="col" >Company Name<img src="../../../assets/images/sort.png" alt="" class="sort-icons" @click="sortBy('company_name')"/></th>
                <th scope="col" class="text-center">Status</th>
                <th scope="col" class="text-center">Verified</th>
                <th scope="col" class="text-center">Effective Date<img src="../../../assets/images/sort.png" alt="" class="sort-icons" @click="sortBy('createdAt')"/></th>
                <th scope="col" class="text-center">Signed By<img src="../../../assets/images/sort.png" alt="" class="sort-icons" @click="sortBy('company_name')"/></th>
                <th scope="col" class="text-center">Title</th>
                <th scope="col" class="text-center">Legal Verifier</th>
                <th scope="col" class="text-center">LV Title<img src="../../../assets/images/sort.png" alt="" class="sort-icons" @click="sortBy('company_name')"/></th>
                <th scope="col" class="text-center">End Date<img src="../../../assets/images/sort.png" alt="" class="sort-icons" @click="sortBy('createdAt')"/></th>
                <th scope="col" class="text-center">Action</th>
              </tr>
            </thead>
            <tbody v-if="displayedCompany.length > 0">
                <tr class="table-row" v-for="(companies, index) in displayedCompanies" :key="index">
                <td class="" @click="NDAFormTable(companies)">
                  <!-- checkbox -->
                    <!-- <label class="chck">
                      <input type="checkbox">
                      <span class="geekmark"></span>
                     </label> -->
                     <!-- <span class="pl-4" v-if="companies.company_name==null">N/A</span> -->
                      <span class=" " :class="{'text-danger': companies.status === 2}">
                        {{ companies.company_name }}
                      </span>
                </td>
                <!-- status  -->
                
                <!-- <td v-if="companies.nda_status !== 1" class="text-center active" @click="NDAFormTable(companies)">
                <button class="notSigned">Not Signed</button>
                </td>
                <td v-else-if="companies.nda_status === 1" class="text-center active" @click="NDAFormTable(companies)">
                <button class="active">Active</button>
                </td>
                
                <td v-else-if="companies.global_ndas[0].status === 2" class="text-center" @click="NDAFormTable(companies)">
                  <button class="terminated">Terminated</button>
                </td>
                <td v-else-if="companies.global_ndas[0].status == 0" class="text-center" @click="NDAFormTable(companies)">
                      <button class="expired">Expired</button>
                </td> -->
                <td v-if="companies.global_ndas[0].status == null" class="text-center active" @click="NDAFormTable(companies)">
                <button class="notSigned">Not Signed</button>
                </td>
                <td v-else-if="companies.global_ndas[0].status === 1" class="text-center active" @click="NDAFormTable(companies)">
                <button class="active">Active</button>
                </td>
                
                <td v-else-if="companies.global_ndas[0].status === 2" class="text-center" @click="NDAFormTable(companies)">
                  <button class="terminated">Terminated</button>
                </td>
                <td v-else-if="companies.global_ndas[0].status == 0" class="text-center" @click="NDAFormTable(companies)">
                      <button class="expired">Expired</button>
                </td>
                
                <td class="text-center" @click="NDAFormTable(companies)">
            <div class="company-verification m-auto" v-if="(companies.global_ndas[0] && companies.global_ndas[0].verify_status == 1) || (companies.global_ndas[1] && companies.global_ndas[1].verify_status == 1)">
              <img src="../../../assets/images/checkmark.png" alt="" />  
              <img src="../../../assets/images/Check icon.png" class="d-none" alt="" />             
            </div>
          </td>

                <td class="text-center"  @click="NDAFormTable(companies)">
                  {{ companies.createdAt | moment("MMM D, YYYY") }}
                </td>

                <td class="text-center" v-if="companies.global_ndas[0]" @click="NDAFormTable(companies)">
                  <span >{{ companies.global_ndas[0].user.first_name }} {{ companies.global_ndas[0].user.last_name }}</span>
                </td>
                <td v-else class="text-center"></td>


                <td class="text-center" v-if="companies.global_ndas[0] && companies.global_ndas[0].user" @click="NDAFormTable(companies)">
                  <span>{{ companies.global_ndas[0].user.title }}</span>
                </td>
                <td v-else class="text-center"></td>

                <td class="text-center" v-if="companies.company_legal_registries[0]" @click="NDAFormTable(companies)">
                  <span>{{ companies.company_legal_registries[0].legal_contact_name }}</span>
                </td>
                <td v-else class="text-center"></td>



                <td class="text-center" v-if="companies.company_legal_registries[0]" @click="NDAFormTable(companies)">
                  <span>{{ companies.company_legal_registries[0].legal_contact_title }}</span>
                </td>
                <td v-else class="text-center"></td>


                <td class="text-center" v-if="companies.global_ndas[0]" @click="NDAFormTable(companies)">
                  <span class="pl-4" v-if="companies.global_ndas[0].end_agreement_date==null">N/A</span>
                  <span class="pl-4" v-else>{{ companies.global_ndas[0].end_agreement_date | moment("MMM D, YYYY") }}</span>
                </td>
                <td v-else class="text-center"></td>

                
                <td class="text-center" v-if="companies.global_ndas[0]">
                  <div>
                    <!-- <i class="fa fa-ellipsis-v" aria-hidden="true" @click="openUserMenu" v-click-outside="userMenuOutside"></i>
                    <div v-if="userMenu" class="menu-options">
                      <ul>
                        <router-link to="" class="menu-link" >Activate</router-link>
                        <router-link to="" class="menu-link">Deactivate</router-link>
                      </ul>
                    </div> -->
                    <div class="dropdown" >
                    <button class="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-menu p-0 m-0 cur-pointer"  aria-labelledby="dropdownMenuButton" :key="companies.global_ndas[0].id">
                      <a class="dropdown-item" v-if="companies.global_ndas[0].status == 2" @click="signAgain(companies)" >Renew</a>
                      <a class="dropdown-item text-danger" v-if="companies.global_ndas[0].status == 1" @click="showAlert(companies)">Terminate</a>
                      <a class="dropdown-item"  v-if="companies.status == 2" @click="activeComp(companies)">Add to registry</a>
                      <a class="dropdown-item"  v-else-if="companies.status == 1" @click="deActiveComp(companies)">Remove from registry</a>
                    </div>
                  </div>
                  </div>
                </td>
                <td v-else class="text-center"></td>

              </tr>
            </tbody>
            
          </table>
          <div v-if="displayedCompany.length  == 0" class="h-75 d-flex flex-column justify-content-center">
              <p class="no-registry">No companies found</p>
          </div>
        </div>
        <div class="agreement-bottom">
          <span class="rows-per-page">Rows per page</span>
          <p class="pages">
            {{ page.currentPage }}-{{ page.totalPages }} of {{ page.totalPages }}
          </p>
          <router-link
            v-if="page.currentPage !== 1"
            :class="{ disabled: page.currentPage === 1 }"
            :to="{ query: { page: page.currentPage - 1 } }"
            ><img src="../../../assets/images/left.png" alt=""
          /></router-link>
          <router-link
            v-if="page.currentPage !== page.totalPages"
            :class="{ disabled: page.currentPage == page.totalPages }"
            :to="{ query: { page: page.currentPage + 1 } }"
            ><img src="../../../assets/images/right.png" alt=""
          /></router-link>
        </div>
      </div>
    </div>
  </template>

<script>
  import axios from "axios";
  import {parse} from 'json2csv';

  export default {
    name: "company",
    props: {
      displayedCompany: Array,
      page: Object,
      message: Boolean,
      filterTextRecievedUser: String,
      searchedUser: String,
      getDataCompany:{
        type: Function
      }
      },
    data: function () {
      return {
        companiesForCSV: [],
        companies: [], // your array of table data
        sortKey: '',
        reverse: false,
        sortColumn: '',
        sortDirection: '',
        userMenu: false,
        data: {},
        company: null,
        filterText: "Select",
        filter: {
          search: "",
        },
      };
    },
    methods: { 
      NDAFormTable(companies){
          if (companies.global_ndas.length === 0) {
          // Show SweetAlert that the company is unsigned
          this.$swal({
            title: "Unsigned Company!",
            text: "This company has not yet signed the agreement",
            icon: "info",
            confirmButtonText: "OK",
          });          
        } else {
            setTimeout(() => {
            this.$root.$emit("dataForCompanySigned", companies);
          }, 500); 
          this.$router.push({ name: "CompanySigned" });
        }
      },
    openUserMenu() {
    this.userMenu = !this.userMenu;
    },
     
      serach() {
        this.filterText = "SearchedUser";
        this.$root.$emit("filtersCallForCompany", this.filter, this.filterText);
      },
      signAgain(companies){
        this.$swal({
          title: "Do you want to renew this agreement?",
          // text: "Please Verify.",
          icon: "info",
          confirmButtonText: "Yes",
          showCancelButton: true,
        }).then((result) => {

          if (result.isConfirmed) {
            var formData = new FormData();
            
            formData.append("agreement_id", companies.global_ndas[0].id);
            formData.append("status", "1");
            formData.append("state", "3");
            
            axios
              .post(process.env.VUE_APP_API_URL + "agreement/updateStatus", formData)
              .then((response) => {
                if (response.data.statusCode === 200) {
                  this.getDataCompany();
                  this.$swal("Renewed!", "This agreement has been renewed.", "success");
                }
              })
              .catch((error) => {
                if (error.response.status === 500) {
                  alert(error.response.data.message);
                }
              });
          }
        });
      },
      showAlert(companies) {
        console.log(companies.global_ndas[0].id)
        this.$swal({
          title: "Are you sure you want to terminate this agreement",
          // text: "You will not receive a refund for the remaining year and must pay again to renew. Please see termination policy before confirming.",
          icon: "info",
          confirmButtonText: "Terminate",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            var formData = new FormData();
            
            formData.append("agreement_id", companies.global_ndas[0].id);
            formData.append("status", "2");
            formData.append("state", "2");
            axios
              .post(process.env.VUE_APP_API_URL + "agreement/updateStatus", formData)
              .then((response) => {
                if (response.data.statusCode === 200) {
                  this.getDataCompany();
                  this.$swal("Terminated!", "This agreement has been terminated.", "success");
                }
              })
              .catch((error) => {
                if (error.response.status === 500) {
                  alert(error.response.data.message);
                }
              });
          }
        });
      },
      activeComp(companies){
        this.$swal({
          title: "Confirm to add to registry.",
          // text: "Please Verify.",
          icon: "info",
          confirmButtonText: "Confirm",
          showCancelButton: true,
        }).then((result) => {

          if (result.isConfirmed) {
            var formData = new FormData();
            
            formData.append("registry_id", companies.id);
            formData.append("status", "1");
            
            axios
              .post(process.env.VUE_APP_API_URL + "registry/updateStatus", formData)
              .then((response) => {
                if (response.data.statusCode === 200) {
                  this.getDataCompany();
                  this.$swal("Added!", "This company has been added to registry.", "success");
                }
              })
              .catch((error) => {
                if (error.response.status === 500) {
                  alert(error.response.data.message);
                }
              });
          }
        });
      },
      deActiveComp(companies){
        this.$swal({
          title: "Confirm to remove from registry.",
          // text: "Please Verify.",
          icon: "info",
          confirmButtonText: "Confirm",
          showCancelButton: true,
        }).then((result) => {

          if (result.isConfirmed) {
            var formData = new FormData();
            
            formData.append("registry_id", companies.id);
            formData.append("status", "2");
            
            axios
              .post(process.env.VUE_APP_API_URL + "registry/updateStatus", formData)
              .then((response) => {
                if (response.data.statusCode === 200) {
                  this.getDataCompany();
                  this.$swal("Removed!", "This company has been removed from registry.", "success");
                }
              })
              .catch((error) => {
                if (error.response.status === 500) {
                  alert(error.response.data.message);
                }
              });
          }
        });
      },
      sortBy(sortKey) {
        if (this.sortKey === sortKey) {
          this.reverse = !this.reverse;
        } else {
          this.sortKey = sortKey;
          this.reverse = false;
        }

        this.companies.sort((a, b) => {
          let sortA = a[sortKey];
          let sortB = b[sortKey];

          if (typeof sortA === 'string') {
            sortA = sortA.toLowerCase();
            sortB = sortB.toLowerCase();
          }

          if (sortA < sortB) {
            return this.reverse ? 1 : -1;
          }

          if (sortA > sortB) {
            return this.reverse ? -1 : 1;
          }

          return 0;
        });
      },
      async downloadCSV() {
      try {
        // Make HTTP request to retrieve data from ${process.env.VUE_APP_API_URL}registry/listing
        const response = await axios.get(`${process.env.VUE_APP_API_URL}company/exportListing`);
        this.companiesForCSV = response.data.data.rows;
        // Convert data to JSON format
        const jsonData = this.companiesForCSV.map(company => ({
          'Company Name': company.company_name,
          'Status': company.nda_status === 1 ? 'Active' : 'Not Signed',
          'Verified': (company.global_ndas[0] && company.global_ndas[0].verify_status === 1) || (company.global_ndas[1] && company.global_ndas[1].verify_status === 1),
          'Effective Date': this.$moment(company.createdAt).format('MMM D, YYYY'),
          'Signed By': company.global_ndas[0] ? `${company.global_ndas[0].user.first_name} ${company.global_ndas[0].user.last_name}` : '',
          'Title': company.global_ndas[0] ? company.global_ndas[0].user.title : '',
          'Legal Verifier': company.company_legal_registries[0] ? company.company_legal_registries[0].legal_contact_name : '',
          'LV Title': company.company_legal_registries[0] ? company.company_legal_registries[0].legal_contact_title : '',
          'End Date': company.global_ndas[0] && company.global_ndas[0].end_agreement_date ? this.$moment(company.global_ndas[0].end_agreement_date).format('MMM D, YYYY') : 'N/A',
        }));

      // Convert JSON data to CSV format
      const csvData = parse(jsonData);

      // Download CSV file
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'Companies.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  },
  },
    computed: {
    classObject: function () {
      return {
        'user-table': this.message,
        'user1-table': !this.message,
      }
    },
    // Sorting data array
    displayedCompanies() {
    return this.displayedCompany.slice().sort((a, b) => {
      if (!this.sortKey) {
        return 0;
      }

      let sortA = a[this.sortKey];
      let sortB = b[this.sortKey];

      if (typeof sortA === 'string') {
        sortA = sortA.toLowerCase();
        sortB = sortB.toLowerCase();
      }
      if (sortA < sortB) {
        return this.reverse ? 1 : -1;
      }
      if (sortA > sortB) {
        return this.reverse ? -1 : 1;
      }
      return 0;
    });
  },
  }
  };
  </script>
  
  <style scoped>
.btn:focus{
  box-shadow: none!important;
}
.dropdown-menu{
  min-width: 6rem;
  font-size: 14px;
  top: -3px!important;
  left: -30px!important;
}
.dropdown-item {
    padding: 0.25rem 0.5rem;
    }
</style>