<template>
    <div class="profile-wrapper">
      <ul class="nav nav-tabs nav-pills nav-fill w-100" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="company-user-tab"
            data-toggle="tab"
            href="#company-user"
            role="tab"
            aria-controls="company-user"
            aria-selected="true"
          >
            <img src="../../../assets/images/white-user.png" alt="" />
            Company Users
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="company-agreements-tab"
            data-toggle="tab"
            href="#company-agreements"
            role="tab"
            aria-controls="company-agreements"
            aria-selected="false"
          >
            <img src="../../../assets/images/company-profile-icon.png" alt="" />
            Company Agreements</a
          >
        </li>
      </ul>

    </div>
  </template>
  
  <script>
  export default {
    name: "CompanyeTop",
  };
  </script>
  
  <style></style>
  