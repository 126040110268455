<template>
    <!-- <div> -->
    <div class="container-fluid filter-content">
      <div class="row no-gutters p-0 px-1 mb-2 justify-content-between">
        <div class="col-12 d-flex ">
          <h5 class="mb-md-1">Filters</h5>
          <button class="custom-btn filter-btn  mt-0 ml-auto" @click="clearFilter">
            Clear Filter
          </button>
          <span class="toggle float-right px-2 cur-pointer"
           @click="toggleVisible" 
                ><i class="fa fa-angle-up" aria-hidden="true" v-if="visible"></i>
                <i class="fa fa-angle-down" aria-hidden="true" v-else></i>
          </span>
        </div>
      </div>
    
      <div>
        <div class="row align-items-center justify-content-center" v-if="visible">
            
        <div class="col-12 col-lg-4 p-0 pr-2 ">
          <div class="form-group">
            <div class="date-pick">
              <VueDatePicker class="h-100"  placeholder="Effective Date" v-model="filter.effective_date" @input="setSelected" :color="color"/>
            </div>
          </div>
        </div>
        <div class="col-12  col-lg-4 p-0 pr-2 ">
          <div class="form-group">
            <div class="date-pick">
              <VueDatePicker class="h-100"  @input="setSelected" placeholder="Expiration Date" v-model="filter.expiration_date" :color="color"/>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 p-0 pr-2 ">
          <div class="form-group">
            <div class="date-pick">
              <VueDatePicker class="h-100"  v-model="filter.termination_date" @input="setSelected" placeholder="Termination Date" :color="color"/>
            </div>
          </div>
        </div>
        
      </div>
      <div class="row" v-if="visible">
        <div class="col-12 col-lg-4 p-0 pr-2 ">
          <div class="form-group">
            <v-select
              v-model="statusText"
              :options="statusList"
              class="custom-select p-0"
              label="text"
              :placeholder="statusPlaceholder"
              @input="setSelectedStatus"
            />
          </div>
        </div>
        <div class="col-12  col-lg-4 p-0 pr-2 ">
          <div class="form-group">
            <!-- <v-select
              v-model="filter.state"
              :options="stateList"
              class="custom-select p-0"
              label="name"
              :placeholder="statePlaceholder"
              @input="setSelected"
            /> -->
            <v-select
              v-model="verifyText"
              :options="verifyList"
              class="custom-select p-0"
              label="text"
              :placeholder="statePlaceholder"
              @input="setSelectedVerified"
            />
          </div>
        </div>
        <div class="col-12  col-lg-4 p-0 pr-2">

        </div>
      </div>
      <!-- <div class="row col-12 pr-lg-5">
        <button class="custom-btn filter-btn mr-lg-2 mt-0 ml-auto" @click="clearFilter">
          Clear Filter
        </button>
      </div> -->
      </div>
    </div>
    <!-- </div> -->
  </template>
  
  <script>
  import { VueDatePicker } from '@mathieustan/vue-datepicker';
  import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
  let states = require("../../../assets/js/states");
  export default {
    name: "RegistryFilter",
    components: {
      VueDatePicker,
    },
    data: function() {
      return {
        visible: false,
        color: 'none',
        stateList: states,
        statusText: "",
        verifyText: "",
        statusList: [
          { text: "Active", id: "1" },
          { text: "Terminated", id: "2"},
          { text: "Expired", id: "0" }
        ],
        verifyList: [
          { text: "Verified", id: "1" },
          { text: "Un-verified", id: "0" },
        ],
        statePlaceholder: "Select Verified",
        statusPlaceholder: "Select Status",
        filterText: "Select",
        filter: {
          state: "",
          size: "",
          effective_date: null,
          expiration_date: null,
          termination_date:  null,
          status: "",
          verified: ""

        },
      };
    },
      methods: {
      toggleVisible(){
        this.visible = !this.visible
        this.$emit("showChange", this.visible)
      },
      clearFilter() {
        this.filter = {};
        this.statusText = "";
        this.verifyText= "",
        this.$root.$emit("filtersCallForCompany", this.filter);
      },
      setSelectedStatus(value){
        if (value != null) {
          this.filter.status = value.id;
          this.$root.$emit("filtersCallForCompany", this.filter);
        } else {
          this.filter.status = ""
          this.$root.$emit("filtersCallForCompany", this.filter);
        }
      },
      setSelectedVerified(value){
        if (value != null) {
          this.filter.verified = value.id;
          console.log(value.id)

          this.$root.$emit("filtersCallForCompany", this.filter);
        } else {
          this.filter.verified = ""
          console.log(this.filter.verified)
          this.$root.$emit("filtersCallForCompany", this.filter);
        }
      },
      
      setSelected(value) {
        if (value != null) {
          this.filter.state = value.name;
          this.$root.$emit("filtersCallForCompany", this.filter);
        } else {
          this.$root.$emit("filtersCallForCompany", this.filter);
        }
      },  
    },
  };
  </script>
  
  <style scoped>
  .form-group {
      margin-bottom: 0.5em;
  }
  .date-pick {
    height: 45px;
    width: 100%;
    border: 1px solid var(--light-gray)!important;
    padding-top: auto;
    border-radius: 0.25rem;
    position: relative;
  }
  
  .custom-select {
    border: 1px solid var(--light-gray)!important;
    border-radius: 0.25rem;
  }
  
  </style>
  