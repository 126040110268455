<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper">
          <div class="col-12 px-0">
              <div
                v-if="showLoader == 1"
                class="lds-dual-ring-registry"
                id="loading"
              ></div>
          </div>
        <SignedAgreements
          v-if="showLoader == 0"
          :searched="filter.search"
          :displayedAgreement="agreement"
          :page="pager"
          :filterTextRecieved="filterText"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import SignedAgreements from "../components/agreements/SignAgreements.vue";

export default {
  name: "SignedAgreement",
  data: function () {
    return {
      errorMessage: null,
      agreement: [],
      showLoader: 0,
      page: 1,
      pager: {},
      perPage: 3,
      pages: [],
      success: null,
      error: null,
      count: 0,
      filter: {},
      filterText: "Select",
    };
  },
  methods: {
    getData() {
      this.showLoader = 1;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}agreement/history?page=${
            this.page ? this.page : ""
          }&size=10&startDate=${
            this.filter.startDate ? this.filter.startDate : ""
          }&endDate=${this.filter.endDate ? this.filter.endDate : ""}&search=${
            this.filter.search ? this.filter.search : ""
          }`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.agreement = response.data.data.listing.reverse();
            this.pager = response.data.data;
            this.count = response.data.data.totalItems;
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
  },
  mounted() {
    this.$root.$on("filtersCall", (data, filterText) => {
      this.filter = data;
      this.filterText = filterText;
      this.getData();
    });

    // localStorage.getItem("ListlastPage");
  },
  watch: {
    "$route.query.page": {
      immediate: true,
      handler(page) {
        page = parseInt(page) || 1;
        if (page !== this.pager.currentPage || page == this.pager.totalPages + 1) {
          this.showLoader = 1;
          axios
            .post(
              `${process.env.VUE_APP_API_URL}agreement/history?page=${
                page ? page : ""
              }&size=10&endDate=${
                this.filter.endDate ? this.filter.endDate : ""
              }&startDate=${this.filter.startDate ? this.filter.startDate : ""}&search=${
                this.filter.search ? this.filter.search : ""
              }`,
              {}
            )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.agreement = response.data.data.listing.reverse();
                this.pager = response.data.data;
                this.count = response.data.data.totalItems;
                this.showLoader = 0;
              } else {
                this.errorMessage = response.data.message;
                this.showLoader = 0;
              }
            });
        }
      },
    },
  },
  components: {
    Navbar,
    LeftMenu,
    SignedAgreements,
  },
};
</script>
<style  scoped>
.lds-dual-ring-registry {
  position: absolute;
  z-index: 999;
  padding-top: 50% !important;
}
</style>