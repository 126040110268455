<template>
    <div class="app-page">
      <Navbar />
      <div class="main-wrapper">
        <LeftMenu />
        <div class="registry-wrapper container-fluid">
          <div class="row">
            <div class="col-12 px-0">
            <div
              v-if="showloader == 0"
              class="lds-dual-ring-registry"
              id="loading"
            ></div>
          </div>
            <div class="col-lg-6 pl-0">
              <adminCompanyView />
            </div>
            <div class="col-lg-6 mt-3 px-0">
            <NDAFormTable />
          </div>
            
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import LeftMenu from "../../../components/Enterprise/leftmenu/LeftMenu.vue";
  import Navbar from "../../../components/header/Navbar.vue";
  import adminCompanyView from "../../../components/admin/companies/adminCompView.vue"
  import NDAFormTable from "../../../components/ndaform/NDAFormTable.vue";

  import { mapGetters } from "vuex";
  import axios from "axios";
  export default {
    name: "companyView",
    data() {
      return {
        details: {},
        compDATA:{},
        showloader: 0,
        fullPage: false,
        loading: true,
      };
    },
    components: {
      Navbar,
      LeftMenu,
      adminCompanyView,
      NDAFormTable
    },
    computed: {
      ...mapGetters("auth", ["user"]),
    },
    methods:{
      getData(){
        var formData = new FormData();
        formData.append("registry_id", this.compDATA.id);

        axios
          .post(process.env.VUE_APP_API_URL + "company/view", formData)
          .then((response) => {
            if (response.data.statusCode === 200) {
                this.details = response.data.data;
                this.$root.$emit("ndaAgree", this.details);
                this.$root.$emit("agreeView", this.details);
                this.$root.$emit("companiesView", this.details);
                this.showloader = 1;
            }
          })
          .catch((error) => {
            if (error.response.status === 500) {
              alert(error.response.data.message);
            }
          });
  
      }
    },
  
    
    mounted() {
        
      this.$root.$on("dataForCompaniesView", (data) => {
        this.compDATA = data
        this.getData();
      });
      if(this.user.agreement != null ){
        this.compDATA = this.user.agreement
        this.getData();
      }
    },
  };
  </script>
  <style scoped>
  .lds-dual-ring-registry {
    position: absolute;
    z-index: 999;
    padding-top: 50% !important;
  }
  </style>
  