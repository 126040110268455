<template>
    <div>
      <div class="registry-sign-up-form">
        <div class="signed-agreements">
          <div class="heading">
            <h5>Company Users</h5>
          </div>
          <div class="menus">
            <form class="form-group mb-0">
              <button type="submit" class="search-icon"  @click="serach()">
                <img src="../../../assets/images/search.png" alt="" />
              </button>
              <input
                class="form-control"
                type="search"
                placeholder="Type Here..."
                v-model="filter.search"
              />
            </form>
            <button class="custom-btn2" @click="serach()">Search</button>
            <div class="dropdown pr-2" >
                    <button class="btn p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-menu p-0 m-0 cur-pointer" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item"  @click="downloadCSV()">Export CSV</a>
                    </div>
                </div>
          </div>
        </div>

        <div class="" v-bind:class="classObject">
          <table class="table table-hover">
            <thead class="thead-light">
              <tr>
                <!-- checkbox -->
                <!-- <th scope="col" class="" >
                    <label class="chck">
                      <input type="checkbox">
                      <span class="geekmark signCheck"></span>
                     </label>
                     <div class="ml-3">User Name
                      <img src="../../../assets/images/sort.png" alt="" class="sort-icons" @click="sortBy('first_name')"/>
                    </div>
                  </th> -->
                <th scope="col" >User Name<img src="../../../assets/images/sort.png" alt="" class="sort-icons" @click="sortBy('first_name')"/></th>
                <th scope="col" class="text-center" >Status</th>
                <th scope="col" class="text-center">User Login<img src="../../../assets/images/sort.png" alt="" class="sort-icons"  @click="sortBy('email')"/></th>
                <th scope="col" class="text-center">Register Date<img src="../../../assets/images/sort.png" alt="" class="sort-icons"  @click="sortBy('createdAt')"/></th>
                <th scope="col" class="text-center">Company Name<img src="../../../assets/images/sort.png" alt="" class="sort-icons"   @click="sortBy('first_name')"/></th>
                <th scope="col" class="text-center" >Verification Date</th>
                <th scope="col" class="text-center" >Last Active Date<img src="../../../assets/images/sort.png" alt="" class="sort-icons"  @click="sortBy('last_login_date')"/></th>
                <th scope="col" class="text-center">Action</th>

              </tr>
            </thead>
            <tbody v-if="displayedUsers.length > 0">
              <!-- <tr class="table-row" v-for="(Users, index) in displayedUsers" :key="index"> -->
                <tr class="table-row" v-for="(Users, index) in sortedUsers" :key="index">

                <td class="" @click="userview(Users)">
                  <!-- checkbox -->
                    <!-- <label class="chck">
                      <input type="checkbox">
                      <span class="geekmark"></span>
                     </label> -->
                      <span class="" >{{ Users.first_name }} {{ Users.last_name }} </span>
                </td>

                <!-- status  -->
                <!-- <td v-if="Users.registry === null || Object.keys(Users.registry).length === 0"  class="text-center"></td> -->
                <td  class="text-center">
                  <template v-if="Users.email_verification_status == 1">
                    <button class="expired">Not Verified</button>
                  </template>
                  <template v-else-if="Users.status == 1">
                    <button class="userActive">Active</button>
                  </template>
                  <template v-else-if="Users.status == 2">
                    <button class="terminated">Deactivate</button>
                  </template>
                  
                  <template v-else ></template>
                </td>

                <!-- User Login email -->
                <td class="text-center" @click="userview(Users)">
                  {{ Users.email }}
                </td>
                
                <!-- user Register Date -->
                  <td class="text-center"  @click="userview(Users)">
                    {{ Users.createdAt | moment("MMM D, YYYY") }}
                  </td>
              
                
                <!-- user Company name -->
                  <td class="text-center" v-if="Users.registry" @click="userview(Users)">
                    <span >{{ Users.registry.company_name }}</span>
                  </td>
                  <td v-else class="text-center"></td>
   

                  <!-- user Verification date -->
                <td class="text-center" v-if="Users.registry" @click="userview(Users)">
                  <!-- <span>{{ Users.registry.global_ndas[0].verification_date }}</span> -->
                  <span v-if="Users.registry.global_ndas.length === 0 || !Users.registry.global_ndas[0].verification_date"></span>
                  <span v-else>{{ Users.registry.global_ndas[0].verification_date | moment("MMM D, YYYY") }}</span>
                </td>
                <td v-else class="text-center"></td>

                <td class="text-center" @click="userview(Users)">
                  <!-- <span class="pl-4" v-if="Users.last_login_date==null"></span>
                  <span class="pl-4" v-else>{{ Users.registry.updatedAt | moment("MMM D, YYYY") }}</span> -->
                  <span>{{  Users.last_login_date ? Users.last_login_date : Users.updatedAt | moment("MMM D, YYYY") }}</span>
                </td>
                
                <td class="text-center">
                  <!-- <div v-if="Users.registry.nda_status == 1"> -->
                    <div>
 
                    <div class="dropdown">
                    <button class="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-menu p-0 m-0 cur-pointer"  aria-labelledby="dropdownMenuButton"  :key="Users.id">
                      <a class="dropdown-item"  v-if="Users.status == 2" @click="active(Users)">Activate</a>
                      <a class="dropdown-item"  v-if="Users.status == 1" @click="deActive(Users)">DeActivate</a>
                    </div>
                  </div>
                  </div>
                  <!-- <button v-else class="custom-btn3" @click="viewAgreement(agreement)">
                    View
                  </button> -->
                </td>

              </tr>
            </tbody>
            <p v-else class="no-registry">No users found.</p>
          </table>
        </div>
        <div class="agreement-bottom">
          <span class="rows-per-page">Rows per page</span>
          <p class="pages">
            {{ page.currentPage }}-{{ page.totalPages }} of {{ page.totalPages }}
          </p>
          <router-link
            v-if="page.currentPage !== 1"
            :class="{ disabled: page.currentPage === 1 }"
            :to="{ query: { page: page.currentPage - 1 } }"
            ><img src="../../../assets/images/left.png" alt=""
          /></router-link>
          <router-link
            v-if="page.currentPage !== page.totalPages"
            :class="{ disabled: page.currentPage == page.totalPages }"
            :to="{ query: { page: page.currentPage + 1 } }"
            ><img src="../../../assets/images/right.png" alt=""
          /></router-link>
        </div>
      </div>
    </div>
  </template>
  <script>
import axios from "axios";
import {parse} from 'json2csv';


  export default {
    name: "UsersDetailSign",
    props: {
      displayedUsers: Array,
      page: Object,
      message: Boolean,
      filterTextRecievedUser: String,
      searchedUser: String,
      getDataCompUser:{
        type: Function
      }
    },
    data: function () {
      return {
        UsersForCSV: [],
        sortColumn: '',
        sortDirection: '',
        userMenu: false,
        data: {},
        company: null,
        filterText: "Select",
        filter: {
          search: "",
        },
      };
    },

    methods: {
    openUserMenu() {
      this.userMenu = !this.userMenu;
    },
      userview(Users) {
      setTimeout(() => {
        this.$root.$emit("dataForUsers", Users);
      }, 500);

      this.$router.push({ name: "usersView" });
    },
      
    serach() {
        this.filterText = "SearchedUser";
        this.$root.$emit("filtersCallForUser", this.filter, this.filterText);
    },
    active(Users){
        this.$swal({
          title: "Are you sure you want to activate this user?",
          // text: "Please Verify.",
          icon: "info",
          confirmButtonText: "Yes",
          showCancelButton: true,
        }).then((result) => {

          if (result.isConfirmed) {
            var formData = new FormData();
            
            formData.append("user_id", Users.id);
            formData.append("status", "1");
            
            axios
              .post(process.env.VUE_APP_API_URL + "user/updateStatus", formData)
              .then((response) => {
                if (response.data.statusCode === 200) {
                  this.getDataCompUser();
                  this.$swal("Activated!", "This user has been activated.", "success");
                }
              })
              .catch((error) => {
                if (error.response.status === 500) {
                  alert(error.response.data.message);
                }
              });
          }
        });
      },
      deActive(Users){
        this.$swal({
          title: "Are you sure you want to deactivate this user?",
          // text: "Please Verify.",
          icon: "info",
          confirmButtonText: "Yes",
          showCancelButton: true,
        }).then((result) => {

          if (result.isConfirmed) {
            var formData = new FormData();
            
            formData.append("user_id", Users.id);
            formData.append("status", "2");
            
            axios
              .post(process.env.VUE_APP_API_URL + "user/updateStatus", formData)
              .then((response) => {
                if (response.data.statusCode === 200) {
                  this.getDataCompUser();
                  this.$swal("Deactivated!", "This user has been deactivated.", "success");
                }
              })
              .catch((error) => {
                if (error.response.status === 500) {
                  alert(error.response.data.message);
                }
              });
          }
        });
      },
    sortBy(column) {
      if (this.sortColumn === column) {
        // if the same column is clicked, toggle the sort direction
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        // if a different column is clicked, set the sort column and default to ascending order
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }
    },
    async downloadCSV() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}user/exportCsvListing`);
        this.UsersForCSV = response.data.data.rows;
        // Convert data to JSON format
        const jsonData = this.UsersForCSV.map(Users => ({
          'User Name': Users.first_name +" "+ Users.last_name,
          'Status': Users.email_verification_status == 1 ? 'Not Verified' : 
            (Users.status == 1 ? 'Active' : (Users.status == 2 ? 'Deactivate' : '')),
          'User Login': Users.email,
          'Register Date': this.$moment(Users.createdAt).format('MMM D, YYYY'),
          'Company Name': Users.registry && Users.registry.company_name ? Users.registry.company_name : '',
          'Verification Date': Users.registry && Users.registry.global_ndas && Users.registry.global_ndas.length > 0 && Users.registry.global_ndas[0].verification_date ? this.$moment(Users.registry.global_ndas[0].verification_date).format("MMM D, YYYY") : '',
          'Last Active Date': Users.last_login_date ? this.$moment(Users.last_login_date).format("MMM D, YYYY") : this.$moment(Users.updatedAt).format("MMM D, YYYY")
          }));

      // Convert JSON data to CSV format
      const csvData = parse(jsonData);

      // Download CSV file
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'Users.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  },
    },
    computed: {
    classObject: function () {
      return {
        'user-table': this.message,
        'user1-table': !this.message,
      }
    },
    sortedUsers() {
    const users = this.displayedUsers.slice(); // create a copy of the displayedUsers array
    if (this.sortColumn && this.sortDirection) {
      users.sort((a, b) => {
        const aVal = a[this.sortColumn];
        const bVal = b[this.sortColumn];
        if (this.sortDirection === 'asc') {
          return aVal.localeCompare(bVal);
        } else {
          return bVal.localeCompare(aVal);
        }
      });
    }
    return users;
  },
  }
  };
  </script>
  
  <style scoped>
.btn:focus{
  box-shadow: none!important;
}
.dropdown-menu{
  min-width: 6rem;
  font-size: 14px;
  top: -3px!important;
  left: -30px!important;
}
.dropdown-item {
    padding: 0.25rem 0.5rem;
    }
</style>