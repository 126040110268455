<template>
    <div class="app-page">
      <Navbar />
      <div class="main-wrapper">
        <LeftMenu />
        <div class="registry-wrapper p-0 bg-transparent overflow-hidden">
            <div class="filter-wrapper">
            <RegistryFilters @showChange="gettingValueChanged" />
          </div>
          <div class="home-registry-wrapper">
            <div class="col-12 px-0">
              <div
                v-if="showLoader == 1"
                class="lds-dual-ring-registry"
                id="loading"
              ></div>
            </div>
            <AdminUsersign
              v-if="showLoader == 0"
              :searchedUser="filter.search"
              :displayedUsers="Users"
              :page="pager"
              :filterTextRecievedUser="filterText"
              :message="parentMessage"
              :getDataUser="getDataUser"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";

  import axios from "axios";
  import LeftMenu from "../../components/Enterprise/leftmenu/LeftMenu.vue";
  import Navbar from "../../components/Enterprise/header/Navbar.vue";
  import AdminUsersign from "../../components/enterpriseAdmin/adminUser.vue";
  import RegistryFilters from "../../components/registry/RegistryFilter.vue";
  
  export default {
    name: "enterprise-users",
    data: function() {
      return {
        parentMessage: false,
        errorMessage: null,
        Users: [],
        showLoader: 0,
        page: 1,
        pager: {},
        perPage: 3,
        pages: [],
        success: null,
        error: null,
        count: 0,
        filter: {},
        filterText: "Select",
        pageSize: "15"
      };
    },
    computed: {
    ...mapGetters("auth", ["user"]),

  },
    methods: {
      gettingValueChanged(value) {
        this.parentMessage = value;
      },
      getDataUser() {
        this.showLoader = 1;
        axios
        .post(
            `${process.env.VUE_APP_API_URL}enterprise/registry/EnterpriseUsers?enterprise_id=${this.user.user.enterprise_id}&page=${
              this.page
            }&size=${this.pageSize ? this.pageSize : ""
            }&registeration_date=${
              this.filter.effective_date ? this.filter.effective_date : ""
            }&verification_date=${
              this.filter.expiration_date ? this.filter.expiration_date : ""
            }&last_active_date=${
              this.filter.termination_date ? this.filter.termination_date : ""
            }&status=${this.filter.status ? this.filter.status : ""}&state=${
              this.filter.state ? this.filter.state : ""
            }&company_size=${this.filter.size ? this.filter.size : ""}&search=${
              this.filter.search ? this.filter.search : ""
            }`,
            {}
          )
          .then(response => {
            if (response.data.statusCode == 200) {
              this.Users = response.data.data.listing;
              // .filter(user => user.role_id !== 5);
              this.pager = response.data.data;
              this.count = response.data.data.totalItems;
              this.showLoader = 0;
            } else {
              this.errorMessage = response.data.message;
              this.showLoader = 0;
            }
          });
      }
    },
  
    watch: {
      "$route.query.page": {
        immediate: true,
        handler(page) {
          page = parseInt(page) || 1;
          if (
            page !== this.pager.currentPage ||
            page == this.pager.totalPages + 1
          ) {
            this.showLoader = 1;
            axios
              .post(
                `${process.env.VUE_APP_API_URL}enterprise/registry/EnterpriseUsers?enterprise_id=${this.user.user.enterprise_id}&page=${
                  page ? page : ""
                }&size=${this.pageSize ? this.pageSize : ""}&verification_date=${
                  this.filter.expiration_date ? this.filter.expiration_date : ""
                }&last_active_date=${
                  this.filter.termination_date ? this.filter.termination_date : ""
                }&registeration_date=${
                  this.filter.effective_date ? this.filter.effective_date : ""
                }&status=${this.filter.status ? this.filter.status : ""}&state=${
                  this.filter.state ? this.filter.state : ""
                }&company_size=${
                  this.filter.size ? this.filter.size : ""
                }&search=${this.filter.search ? this.filter.search : ""}`,
                {}
              )
              .then(response => {
                if (response.data.statusCode == 200) {
                  this.Users = response.data.data.listing;
                  // .filter(user => user.role_id !== 5);
                  this.pager = response.data.data;
                  this.count = response.data.data.totalItems;
                  this.showLoader = 0;
                } else {
                  this.errorMessage = response.data.message;
                  this.showLoader = 0;
                }
              });
          }
        }
      }
    },
    mounted() {
      this.$root.$on("filtersCallForUser", (data, filterText) => {
        this.filter = data;
        this.filterText = filterText;
        if (data.pageSize) {
          this.pageSize = data.pageSize.text;
        }
        this.getDataUser();
      });
      
      // this.getDataUser();
      // localStorage.getItem("ListlastPage");
    },
    components: {
      Navbar,
      LeftMenu,
      AdminUsersign,
      RegistryFilters
    }
  };
  </script>
  <style scoped>
  .lds-dual-ring-registry {
    position: absolute;
    z-index: 999;
    padding-top: 50% !important;
  }
  </style>