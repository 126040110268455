<template>
    <div class="registry-sign-up-form">
      <CardDetailModal />
      <div class="registry-sign-up-form-content d-block" v-if="details.company">
        <h5>Company Profile</h5>
        <div class="company-image" v-if="!details.company.avatar">
          <img src="../../../assets/images/company-logo.png" alt="" />
        </div>
        <div class="company-image" v-else>
          <img :src="details.company.avatar" alt="" />
        </div>
        <div v-if="details.company">
          <p v-if="details.company.status == 1">
            <span class="activeStatus">Active</span>
          </p>
          <p v-else-if="details.company.status == 2">
            <span class="terminateStatus">Terminated</span>
          </p>
          <p v-else>
            <span class="expireStatus">Expired</span>
          </p>
        </div>
       

        <div class="signed-expiration-date container-fluid">
          <div class="row no-gutters">
            <div class="col-md-4 mb-3 mb-md-0">
              <div class="signed-date">
                <span class="heading">Company Name</span>
                <p class="description text-capitalize">
                  {{ details.company.first_name }} {{ details.company.last_name }}
                  <!-- <img
                    v-if="details.agreement && details.agreement.verify_status == 1"
                    src="../../../assets/images/checkmark.png"
                    alt=""
                  /> -->
                </p>
              </div>
            </div>
            <div class="col-md-4 mb-3 mb-md-0">
              <div class="signed-date">
                <span class="heading">Title</span>
                <p class="description">
                  {{ details.company.title }}
                </p>
              </div>
            </div>
            <div class="col-md-4 mb-3 mb-md-0">
              <div class="signed-date">
                <span class="heading">Created At</span>
                <p class="description">
                  {{ details.company && details.company.createdAt | moment("MMM D, YYYY")  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="signed-expiration-date container-fluid">
          <div class="row no-gutters">
            <div class="col-md-4 mb-3 mb-md-0">
              <div class="signed-date">
                <span class="heading">Company Email</span>
                <p class="description">
                  {{ details.company.email }}
                </p>
              </div>
            </div>
            <div class="col-md-4 mb-3 mb-md-0">
              <div class="signed-date">
                <span class="heading">Company Domain</span>
                <p class="description">
                  {{ details.enterpriseClient && details.enterpriseClient.domain_name }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="company-info">
          <span class="heading">Company Website</span>
          <p class="description">
            {{ details.company.email }}
          </p>
        </div>
        <div class="company-info">
          <span class="heading">Company Domain</span>
          <p class="description">
            {{ details.enterpriseClient && details.enterpriseClient.domain_name }}
          </p>
        </div> -->

      
        
        <!-- <div v-if="user.agreement">
              <span v-if="user.company.id == details.company.id  && user.agreement.status == 1">
            <div class="btns" v-if="user.user.role_id == 2">
              <button type="button" class="custom-btn2" @click="showAlert()">
                Terminate Contract
              </button>
            </div>
            <div class="btns" v-else-if="user.user.role_id == 3">
              <button type="button" class="custom-btn2" @click="showAlert()">
                Terminate Contract
              </button>
              <button v-if="user.agreement.verify_status != 1" type="button" class="custom-btn2" @click="updateVerifyStatus()">
                Confirm
              </button>
            </div>
            <div class="btns" v-else-if="user.user.role_id == 4">
              <button type="button" class="custom-btn2" @click="showAlert()">
                Terminate Contract
              </button>
              <button v-if="user.agreement.verify_status != 1" type="button" class="custom-btn2" @click="updateVerifyStatus()">
                Confirm
              </button>
            </div>
          </span>
          <span v-else-if="user.agreement.status != 1">
            <div class="btns" >
              <button type="button" class="custom-btn2" @click="signAgain()">
                Renew
              </button>
            </div>
          </span>
        </div> -->
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  import CardDetailModal from "../../ndaform/CardDetailModal.vue";
  import axios from "axios"
  export default {
    name: "adminCompanyDetailView",
    components: { CardDetailModal },
    props: {
      details: Object,
    },
    data() {
      return {
        agreement_id: "",
        fullPage: false
      };
    },
    computed: {
      ...mapGetters("auth", ["user"]),
    },
    methods: {
      ...mapActions("auth", ["getUserData"]),
      signAgain(){
        this.$swal({
          title: "Do you want to renew your GlobalNDA?",
          text: "Please Verify.",
          icon: "info",
          confirmButtonText: "Yes",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$root.$emit("openCardDetailModal", null, "Payment");
            
          }
        });
      },
      updateVerifyStatus(){
        this.$swal({
          title: "Confirm!",
          text: "Are you sure you want to verify your GlobalNDA?",
          icon: "info",
          confirmButtonText: "Confirm",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            var formData = new FormData();
            formData.append("agreement_id", this.user.agreement.id);
            axios
              .post(process.env.VUE_APP_API_URL + "agreement/updateVerifyStatus", formData)
              .then((response) => {
                if (response.data.statusCode === 200) {
                  this.getUserData();
                  this.$swal("Congratulations!", "Your GlobalNDA has been verified and a check mark has been added to your company profile.", "success");
                  // this.$router.push({ name: "SignedAgreements" });
                }
              })
              .catch((error) => {
                if (error.response.status === 500) {
                  alert(error.response.data.message);
                }
              });
          }
        });
      },
      showAlert() {
        this.$swal({
          title: "Are you sure you want to terminate your GlobalNDA?",
          text: "You will not receive a refund for the remaining year and must pay again to renew. Please see termination policy before confirming.",
          icon: "info",
          confirmButtonText: "Terminate",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            var formData = new FormData();
            formData.append("agreement_id", this.user.agreement.id);
            formData.append("status", "2");
            formData.append("state", "2");
            axios
              .post(process.env.VUE_APP_API_URL + "agreement/updateStatus", formData)
              .then((response) => {
                if (response.data.statusCode === 200) {
                  this.getUserData();
                  this.$swal("Terminated!", "Your GlobalNDA has been terminated.", "success");
                  // this.$router.push({ name: "Home" });
                }
              })
              .catch((error) => {
                if (error.response.status === 500) {
                  alert(error.response.data.message);
                }
              });
          }
        });
      },
      getCleanURL(url) {
    // Remove search parameters from URL
    const cleanURL = url.split('?')[0];
    // Add http or https protocol if missing
    if (!cleanURL.startsWith('http://') && !cleanURL.startsWith('https://')) {
      return `http://${cleanURL}`;
    }
    return cleanURL;
  },
    },
    // mounted() {
    //   this.$root.$on("companiesView", (data) => {
    //     this.details = data;
    //   });
    // },
  };
  </script>
  
  <style>
  .activeStatus {
    color: #329c00;
    font-weight: 600;
    font-size: 18px;
    line-height: 22.68px;
  }
  .terminateStatus {
    color: #f5222d;
    font-weight: 600;
    font-size: 18px;
    line-height: 22.68px;
  }
  .expireStatus {
    color: #f5222d;
    font-weight: 600;
    font-size: 18px;
    line-height: 22.68px;
  }
  </style>
  

