<template>
  <div>
    <CustomRangeModal />
    <div class="registry-sign-up-form">
      <div class="signed-agreements">
        <div class="heading">
          <h5>Agreements Signed</h5>
        </div>
        <div class="menus">
          <button class="custom-btn2" @click="serach()">Search</button>
          <!-- <button class="custom-btn2 custom-btn3">
            Filter ({{ filterTextRecieved }})
          </button> -->
          <div class="dropdown">
            <button
              @click="openDateFilter"
              v-click-outside="dateFilterOutside"
              class="custom-btn2 custom-btn3"
            >
              <img src="../../assets/images/dropdown.png" alt="" />
            </button>
            <div v-if="dateFilter" id="overlay">
              <div id="myDropdown" class="dropdown-content">
                <a href="#today" @click="getFilters('today')">
                  <img src="../../assets/images/calender.png" alt="" />
                  <div class="date-content">
                    <div class="range">Today</div>
                    <div class="date">{{ new Date() | moment("MMM D") }}</div>
                  </div>
                </a>
                <div class="line-seperator my-0"></div>
                <a href="#yesterday" @click="getFilters('yesterday')">
                  <img src="../../assets/images/calender.png" alt="" />
                  <div class="date-content">
                    <div class="range">Yesterday</div>
                    <div class="date">{{ dateYesterday | moment("MMM D") }}</div>
                  </div>
                </a>
                <div class="line-seperator my-0"></div>
                <a href="#lastweek" @click="getFilters('lastweek')">
                  <img src="../../assets/images/calender.png" alt="" />
                  <div class="date-content">
                    <div class="range">Last Week</div>
                    <div class="date">
                      {{ dateEndWeek | moment("MMM D") }} -
                      {{ dateStartWeek | moment("D") }}
                    </div>
                  </div>
                </a>
                <div class="line-seperator my-0"></div>
                <a href="#lastmonth" @click="getFilters('lastmonth')">
                  <img src="../../assets/images/calender.png" alt="" />
                  <div class="date-content">
                    <div class="range">Last Month</div>
                    <div class="date">
                      {{ dateEndMonth | moment("MMM D") }} -
                      {{ dateStartMonth | moment("MMM D") }}
                    </div>
                  </div>
                </a>
                <div class="line-seperator my-0"></div>
                <a href="#lastmonth" @click="callRangeModal()">
                  <img src="../../assets/images/calender.png" alt="" />
                  <div class="date-content">
                    <div class="range">Custom Range</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" class="search-icon" @click="serach()">
          <img src="../../assets/images/search.png" alt="" />
        </button>
        <input
          type="search"
          v-model="filter.search"
          class="form-control"
          placeholder="Type here..."
        />
      </div>
      <div class="table-content2">
        <table class="table table-hover">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="d-flex">
                <!-- <label class="chck">
                      <input type="checkbox">
                      <span class="geekmark signCheck"></span>
                </label> -->
                Signing Date
              </th>
              <th scope="col" class="text-center">Status</th>
              <th scope="col" class="text-center">Signed By</th>
              <th scope="col" class="text-center">Updated at</th>
              <th scope="col" class="text-center">Expiration Date</th>
              <th scope="col" class="text-center">Action</th>
            </tr>
          </thead>
          <tbody v-if="displayedAgreement.length > 0">
            <tr class="table-row" v-for="(agreement, index) in displayedAgreement" :key="index">
              <td>
                  <!-- <label class="chck">
                      <input type="checkbox">
                      <span class="geekmark"></span>
                     </label> -->
                     <span class="">{{ agreement.global_nda.createdAt | moment("MMM D, YYYY") }}</span>
              </td>

              <td v-if="agreement.nda_state === 1 || agreement.nda_state === 3" class="text-center active">
                <button class="active">Active</button>
              </td>
              <td v-else-if="agreement.nda_state == 2" class="text-center">
                <button class="terminated">Terminated</button>
              </td>
              <td v-else class="text-center">
                <button class="expired">Expired</button>
              </td>
              <!-- <td class="text-center signed-by">
                {{  agreement.user.first_name}} {{  agreement.user.last_name}}
              </td> -->
              <td class="text-center signed-by">
                <template v-if="agreement.global_nda.verifier_user">
                  {{ agreement.global_nda.verifier_user.first_name }} {{ agreement.global_nda.verifier_user.last_name }}
                </template>
                <template v-else>
                  {{ agreement.global_nda.user.first_name }} {{ agreement.global_nda.user.last_name }}
                </template>
              </td>
              <td class="text-center">
                    {{ agreement.createdAt | moment("MMM D, YYYY") }}
              </td>
              <td class="text-center">
                {{ agreement.global_nda.end_agreement_date | moment("MMM D, YYYY") }}
              </td>
              <td class="text-center">
                <button class="custom-btn3" @click="viewAgreement(agreement)">
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
       
        <div v-if=" displayedAgreement.length  == 0" class="h-75 d-flex flex-column justify-content-center">
              <p class="no-registry">No agreement found.</p>
          </div>
      </div>
      <div class="agreement-bottom">
        <span class="rows-per-page">Rows per page</span>
        <!-- <div class="dropdown">
        <button @click="openRowsFilter" v-click-outside="rowsFilterOutside">
          <span>9</span><img src="../../assets/images/angle-down.png" alt="" />
        </button>
        <div
          v-if="rowsFilter"
          id="rowsDropdown"
          class="dropdown-content row-content"
        >
          <a href="#about">10</a>
          <a href="#base">12</a>
          <a href="#blog">15</a>
        </div>
      </div> -->
        <p class="pages">
          {{ page.currentPage }}-{{ page.totalPages }} of {{ page.totalPages }}
        </p>
        <router-link
          v-if="page.currentPage !== 1"
          :class="{ disabled: page.currentPage === 1 }"
          :to="{ query: { page: page.currentPage - 1 } }"
          ><img src="../../assets/images/left.png" alt=""
        /></router-link>
        <router-link
          v-if="page.currentPage !== page.totalPages"
          :class="{ disabled: page.currentPage == page.totalPages }"
          :to="{ query: { page: page.currentPage + 1 } }"
          ><img src="../../assets/images/right.png" alt=""
        /></router-link>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import CustomRangeModal from "./CustomrRangeModal.vue";
export default {
  name: "SignAgreements",
  props: {
    displayedAgreement: Array,
    page: Object,
    filterTextRecieved: String,
    searched: String,
  },
  data: function () {
    return {
      dateFilter: false,
      rowsFilter: false,
      filterText: "Select",
      dateToday: "",
      dateYesterday: "",
      dateStartWeek: "",
      dateEndWeek: "",
      dateStartMonth: "",
      dateEndMonth: "",
      filter: {
        startDate: "",
        endDate: "",
        search: "",
      },
    };
  },
  components: {
    CustomRangeModal,
  },
  methods: {
    viewAgreement(agreement) {
      setTimeout(() => {
        this.$root.$emit("dataForAgreement", agreement);
      }, 500);
      this.$router.push({ name: "AgreementDetail" });
    },
    openDateFilter: function () {
      this.dateFilter = !this.dateFilter;
    },
    dateFilterOutside: function () {
      this.dateFilter = false;
    },

    openRowsFilter: function () {
      this.rowsFilter = !this.rowsFilter;
    },

    rowsFilterOutside: function () {
      this.rowsFilter = false;
    },

    filterFunction() {
      var input, filter, a, i, div, txtValue;
      filter = input.value.toUpperCase();
      div = document.getElementById("myDropdown");
      a = div.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    },
    getFilters(value) {
      if (value === "today") {
        this.filterText = "Today";
        const today = new Date();
        this.filter.startDate = moment(today, "YYYY/MM/DD").format("YYYY-MM-DD");
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      } else if (value === "yesterday") {
        this.filterText = "Yesterday";
        const today = new Date();
        today.setDate(today.getDate() - 1);
        this.filter.startDate = moment(today, "YYYY/MM/DD").format("YYYY-MM-DD");
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      } else if (value === "lastweek") {
        this.filterText = "Last Week";
        var week = new Array();
        let current = moment().subtract(1, "days");
        for (var i = 0; i < 7; i++) {
          week.push(current.format("YYYY-MM-DD"));
          current = current.subtract(1, "days");
        }
        this.filter.startDate = week[0];
        this.filter.endDate = week[6];
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      } else if (value === "lastmonth") {
        this.filterText = "Last Month";
        var month = new Array();
        let current = moment().subtract(1, "days");
        for (var j = 0; j < 30; j++) {
          month.push(current.format("YYYY-MM-DD"));
          current = current.subtract(1, "days");
        }
        this.filter.startDate = month[0];
        this.filter.endDate = month[29];
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      } else if (value === "Custom") {
        this.filterText = "Custom";
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      }
    },
    filterRows() {
      var input, filter, a, i, div, txtValue;
      filter = input.value.toUpperCase();
      div = document.getElementById("rowsDropdown");
      a = div.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    },
    callRangeModal() {
      this.$root.$emit("openCustomRangeModal");
    },
    datesGet() {
      this.filter.search = this.searched;
      const today = new Date();
      //today
      this.dateToday = moment(today, "YYYY/MM/DD").format("YYYY-MM-DD");
      //yesterday
      today.setDate(today.getDate() - 1);
      this.dateYesterday = moment(today, "YYYY/MM/DD").format("YYYY-MM-DD");
      //week
      var week = new Array();
      let current = moment().subtract(1, "days");
      for (var i = 0; i < 7; i++) {
        week.push(current.format("YYYY-MM-DD"));
        current = current.subtract(1, "days");
      }
      this.dateStartWeek = week[0];
      this.dateEndWeek = week[6];
      //month
      var month = new Array();
      for (var j = 0; j < 30; j++) {
        month.push(current.format("YYYY-MM-DD"));
        current = current.subtract(1, "days");
      }
      this.dateStartMonth = month[0];
      this.dateEndMonth = month[29];
    },
    serach() {
      this.filterText = "Searched";
      this.$root.$emit("filtersCall", this.filter, this.filterText);
    },
  },
  mounted() {
    this.datesGet();
    this.$root.$on("dataDateFromModal", (data) => {
      this.filter.startDate = data.start_date;
      this.filter.endDate = data.end_date;
      this.getFilters("Custom");
    });
  },
};
</script>

<style  scoped>
.table-content2 .table .thead-light{
  position: sticky;
    top: 0;
    background: var(--auth-right-background);
}
</style>