<template>
    <div class="app-page">
         <Navbar />
        <div class="main-wrapper">
            <LeftMenu />
            <div class="registry-wrapper p-0 bg-transparent overflow-hidden">             
                <div class="filter-wrapper">
                <companyFilter />
                </div>
                <div class="home-registry-wrapper">
                    <div class="col-12 px-0">
                    <div
                        v-if="showLoader == 1"
                        class="lds-dual-ring-registry"
                        id="loading"
                    ></div>
                    </div>
                    <companyDetailSign
                    v-if="showLoader == 0"
                    :searchedUser="filter.search"
                    :displayedCompany="companies"
                    :page="pager"
                    :filterTextRecievedUser="filterText"
                    :getDataCompAgreements="getDataCompAgreements"
                    />
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import LeftMenu from "../../components/Enterprise/leftmenu/LeftMenu.vue";
import Navbar from "../../components/Enterprise/header/Navbar.vue";
import companyFilter from "../../components/admin/companies/companyFilter.vue";
import companyDetailSign from "../../components/admin/companies/companyAgreements.vue";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "EnterpriseCompanies",
  data() {
    return {
      companies: [],
      Users: [],
      errorMessage: null,
      showLoader: 0,

      page: 1,
      pager: {},
      perPage: 3,
      pages: [],
      filter: {},
      pageSize: "15",
      filterText: "Select",
      success: null,
      error: null,
      count: 0,

      details: {},
      compDATA: {},
      showloader: 0,
      fullPage: false,
      loading: true,
    };
  },
  components: {
    Navbar,
    LeftMenu,
    companyFilter,
    companyDetailSign,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    getDataCompAgreements() {
      this.showLoader = 1;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}enterprise/registry/Agreements?enterprise_id=${this.enterpriseId}&page=${
            this.page
          }&size=${this.pageSize ? this.pageSize : ""}&expiration_date=${
            this.filter.expiration_date ? this.filter.expiration_date : ""
          }&termination_date=${
            this.filter.termination_date ? this.filter.termination_date : ""
          }&effective_date=${
            this.filter.effective_date ? this.filter.effective_date : ""
          }&status=${this.filter.status ? this.filter.status : ""}&verified=${
            this.filter.verified ? this.filter.verified : ""
          }&search=${this.filter.search ? this.filter.search : ""}`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.companies = response.data.data.listing;
            this.pager = response.data.data;
            this.count = response.data.data.totalItems;
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
  },
  watch: {
    "$route.query.page": {
      immediate: true,
      handler(page) {
        page = parseInt(page) || 1;
        if (
          page !== this.pager.currentPage ||
          page == this.pager.totalPages + 1
        ) {
          this.showLoader = 1;
          axios
            .post(
              `${process.env.VUE_APP_API_URL}enterprise/registry/Agreements?enterprise_id=${this.enterpriseId}page=${
                page ? page : ""
              }&size=${this.pageSize ? this.pageSize : ""}&expiration_date=${
                this.filter.expiration_date ? this.filter.expiration_date : ""
              }&termination_date=${
                this.filter.termination_date ? this.filter.termination_date : ""
              }&effective_date=${
                this.filter.effective_date ? this.filter.effective_date : ""
              }&status=${
                this.filter.status ? this.filter.status : ""
              }&verified=${
                this.filter.verified ? this.filter.verified : ""
              }&search=${this.filter.search ? this.filter.search : ""}`,
              {}
            )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.companies = response.data.data.listing;
                this.pager = response.data.data;
                this.count = response.data.data.totalItems;
                this.showLoader = 0;
              } else {
                this.errorMessage = response.data.message;
                this.showLoader = 0;
              }
            });
        }
       
      },
    },
  },
  mounted() {
      this.enterpriseId = this.user.user.enterprise_id;
      this.getDataCompAgreements();

    this.$root.$on("filtersCallForCompany", (data, filterText) => {
      this.filter = data;
      this.filterText = filterText;
      if (data.pageSize) {
        this.pageSize = data.pageSize.text;
      }
      this.getDataCompAgreements();
    });


  },
}
</script>
