<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper container-fluid bg-transparent">
        <div class="row">
          <div class="col-12 px-0">
            <div
              v-if="showloader == 0"
              class="lds-dual-ring-registry"
              id="loading"
            ></div>
          </div>
          
          <div class="col-lg-12 pl-0" >
            <adminCompanyDetailView 
            :details="details"
            />
          </div>

          <div class="col-lg-12 pl-0" v-if="showloader == 1">
            <CompanyeTop />
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="company-user"
                role="tabpanel"
                aria-labelledby="company-user-tab"
              >
                <div class="filter-wrapper mt-3">
                  <RegistryFilters  />
                </div>
                <div class="home-registry-wrapper">
                  <div class="col-12 px-0">
                    <div
                      v-if="showLoader == 1"
                      class="lds-dual-ring-registry"
                      id="loading"
                    ></div>
                  </div>
                  <UsersDetailSign
                    v-if="showLoader == 0"
                    :searchedUser="filter.search"
                    :displayedUsers="Users"
                    :page="pager"
                    :filterTextRecievedUser="filterText"
                    :getDataCompUser="getDataCompUser"
                  />
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="company-agreements"
                role="tabpanel"
                aria-labelledby="company-agreements-tab"
              >
                <div class="filter-wrapper mt-3">
                  <companyFilter />
                </div>
                <div class="home-registry-wrapper">
                  <div class="col-12 px-0">
                    <div
                      v-if="showLoader == 1"
                      class="lds-dual-ring-registry"
                      id="loading"
                    ></div>
                  </div>
                  <companyDetailSign
                    v-if="showLoader == 0"
                    :searchedUser="filter.search"
                    :displayedCompany="companies"
                    :page="pager"
                    :filterTextRecievedUser="filterText"
                    :getDataCompAgreements="getDataCompAgreements"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-6 mt-3 px-0">
            <NDAFormTable />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../../../components/leftmenu/LeftMenu.vue";
import Navbar from "../../../components/header/Navbar.vue";
import adminCompanyDetailView from "../../../components/admin/companies/adminCompDetailView.vue";
import CompanyeTop from "../../../components/admin/companies/companyTop.vue";
import companyFilter from "../../../components/admin/companies/companyFilter.vue";
import companyDetailSign from "../../../components/admin/companies/companyAgreements.vue";
import UsersDetailSign from "../../../components/admin/user/companyUsers.vue";
import RegistryFilters from "../../../components/registry/RegistryFilter.vue";

// import NDAFormTable from "../../../components/ndaform/NDAFormTable.vue";

import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "companyDetailView",
  data() {
    return {
      companies: [],
      Users: [],

      errorMessage: null,
      showLoader: 0,

      page: 1,
      pager: {},
      perPage: 3,
      pages: [],
      filter: {},
      pageSize: "15",
      filterText: "Select",
      success: null,
      error: null,
      count: 0,

      details: {},
      compDATA: {},
      showloader: 0,
      fullPage: false,
      loading: true,
    };
  },
  components: {
    Navbar,
    LeftMenu,
    adminCompanyDetailView,
    CompanyeTop,
    companyFilter,
    companyDetailSign,
    UsersDetailSign,
    RegistryFilters,

    // NDAFormTable
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {

    getData() {
      var formData = new FormData();
      formData.append("user_id", this.compDATA.id);

      axios
        .post(process.env.VUE_APP_API_URL + "enterprise/registry/EnterpriseView", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.details = response.data.data;
            // this.$root.$emit("ndaAgree", this.details);
            // this.$root.$emit("agreeView", this.details);
            // this.$root.$emit("companiesView", this.details);
            this.showloader = 1;
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
    getDataCompAgreements() {
      this.showLoader = 1;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}enterprise/registry/Agreements?enterprise_id=${this.enterpriseId}&page=${
            this.page
          }&size=${this.pageSize ? this.pageSize : ""}&expiration_date=${
            this.filter.expiration_date ? this.filter.expiration_date : ""
          }&termination_date=${
            this.filter.termination_date ? this.filter.termination_date : ""
          }&effective_date=${
            this.filter.effective_date ? this.filter.effective_date : ""
          }&status=${this.filter.status ? this.filter.status : ""}&verified=${
            this.filter.verified ? this.filter.verified : ""
          }&search=${this.filter.search ? this.filter.search : ""}`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.companies = response.data.data.listing;
            this.pager = response.data.data;
            this.count = response.data.data.totalItems;
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
    getDataCompUser() {
      this.showLoader = 1;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}enterprise/registry/EnterpriseUsers?enterprise_id=${this.enterpriseId}&page=${this.page}&size=${
            this.pageSize ? this.pageSize : ""
          }&registeration_date=${
            this.filter.effective_date ? this.filter.effective_date : ""
          }&verification_date=${
            this.filter.expiration_date ? this.filter.expiration_date : ""
          }&last_active_date=${
            this.filter.termination_date ? this.filter.termination_date : ""
          }&status=${this.filter.status ? this.filter.status : ""}&state=${
            this.filter.state ? this.filter.state : ""
          }&company_size=${this.filter.size ? this.filter.size : ""}&search=${
            this.filter.search ? this.filter.search : ""
          }`,
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.Users = response.data.data.listing;
            this.pager = response.data.data;
            this.count = response.data.data.totalItems;
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
  },
  watch: {
    "$route.query.page": {
      immediate: true,
      handler(page) {
        page = parseInt(page) || 1;
        if (
          page !== this.pager.currentPage ||
          page == this.pager.totalPages + 1
        ) 
        {
          this.showLoader = 1;
          axios
            .post(
              `${process.env.VUE_APP_API_URL}enterprise/registry/Agreements?enterprise_id=${this.enterpriseId}page=${
                page ? page : ""
              }&size=${this.pageSize ? this.pageSize : ""}&expiration_date=${
                this.filter.expiration_date ? this.filter.expiration_date : ""
              }&termination_date=${
                this.filter.termination_date ? this.filter.termination_date : ""
              }&effective_date=${
                this.filter.effective_date ? this.filter.effective_date : ""
              }&status=${
                this.filter.status ? this.filter.status : ""
              }&verified=${
                this.filter.verified ? this.filter.verified : ""
              }&search=${this.filter.search ? this.filter.search : ""}`,
              {}
            )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.companies = response.data.data.listing;
                this.pager = response.data.data;
                this.count = response.data.data.totalItems;
                this.showLoader = 0;
              } else {
                this.errorMessage = response.data.message;
                this.showLoader = 0;
              }
            });
        }
        {
          this.showLoader = 1;
          axios
            .post(
              `${process.env.VUE_APP_API_URL}enterprise/registry/EnterpriseUsers?enterprise_id=${this.enterpriseId}&page=${
                page ? page : ""
              }&size=${this.pageSize ? this.pageSize : ""}&verification_date=${
                this.filter.expiration_date ? this.filter.expiration_date : ""
              }&last_active_date=${
                this.filter.termination_date ? this.filter.termination_date : ""
              }&registeration_date=${
                this.filter.effective_date ? this.filter.effective_date : ""
              }&status=${this.filter.status ? this.filter.status : ""}&state=${
                this.filter.state ? this.filter.state : ""
              }&company_size=${
                this.filter.size ? this.filter.size : ""
              }&search=${this.filter.search ? this.filter.search : ""}`,
            )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.Users = response.data.data.listing;
                this.pager = response.data.data;
                this.count = response.data.data.totalItems;
                this.showLoader = 0;
              } else {
                this.errorMessage = response.data.message;
                this.showLoader = 0;
              }
            });
        }
      },
    },
  },

  mounted() {
    this.$root.$on("dataForEnterpriseUsers", (data) => {
      this.compDATA = data;
      this.enterpriseId = this.compDATA.enterprise_id
      this.getDataCompUser();
      this.getDataCompAgreements();
      this.getData();
    });
    if (this.user.agreement != null) {
      this.compDATA = this.user.agreement;
      this.getData();
    }

    this.$root.$on("filtersCallForCompany", (data, filterText) => {
      this.filter = data;
      this.filterText = filterText;
      if (data.pageSize) {
        this.pageSize = data.pageSize.text;
      }
      this.getDataCompAgreements();
    });

    this.$root.$on("filtersCallForUser", (data, filterText) => {
      this.filter = data;
      this.filterText = filterText;
      if (data.pageSize) {
        this.pageSize = data.pageSize.text;
      }
      this.getDataCompUser();
    });
  },
};
</script>
<style scoped>
.lds-dual-ring-registry {
  position: absolute;
  z-index: 999;
  padding-top: 50% !important;
}
</style>
